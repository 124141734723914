<template>
  <view-item title="配方查询">
    <template #operation>
      <en-dropdown @command="operation.option.command">
        <en-button type="primary">新建配方</en-button>
        <template #dropdown>
          <en-dropdown-item command="1">单工序</en-dropdown-item>
          <en-dropdown-item command="2">双工序</en-dropdown-item>
          <en-dropdown-item command="3">三工序</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <en-card class="h-full" body-class="h-full">
      <en-scrollbar>
        <en-collapse :model-value="['origin', 'authorized', 'unauthorized']">
          <en-collapse-item title="原厂配方" name="origin">
            <service-formula-table
              :data="origin.data"
              :loading="origin.loading"
              :method="origin.get"
              pagination
              :paging="origin.paging"
              :height="500"
            >
              <template #prepend>
                <en-table-column label="操作">
                  <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
                    <en-button type="primary" link @click="operation.check.click(row)">查看</en-button>
                  </template>
                </en-table-column>
              </template>
            </service-formula-table>
          </en-collapse-item>

          <en-collapse-item title="高频配方" name="authorized">
            <service-formula-table
              :data="authorized.data"
              :loading="authorized.loading"
              :method="authorized.get"
              pagination
              :paging="authorized.paging"
              :height="500"
            >
              <template #prepend>
                <en-table-column label="操作">
                  <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
                    <en-button type="primary" link @click="operation.check.click(row)">查看</en-button>
                  </template>
                </en-table-column>
              </template>
            </service-formula-table>
          </en-collapse-item>

          <en-collapse-item title="验证配方" name="unauthorized">
            <service-formula-table
              :data="unauthorized.data"
              :loading="unauthorized.loading"
              :method="unauthorized.get"
              pagination
              :paging="unauthorized.paging"
              :height="500"
            >
              <template #prepend>
                <en-table-column label="操作">
                  <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
                    <en-button type="primary" link @click="operation.check.click(row)">查看</en-button>
                  </template>
                </en-table-column>
              </template>
            </service-formula-table>
          </en-collapse-item>
        </en-collapse>
      </en-scrollbar>
    </en-card>
  </view-item>

  <service-formula-detail v-model="detail.visible" :data="detail.data" show-discard-btn @confirm="detail.confirm"></service-formula-detail>
</template>

<script lang="ts">
import ServiceFormulaTable from '@spray/components/service-formula-table.vue'
import ServiceFormulaDetail from '@spray/components/service-formula-detail.vue'

const rsexp = `id,imgUrls,colorCode,referencedCount,brand,vehicleSpec,startYear,preparedDateTime,colorName,paintType,diffCode,procedureType,colorFamilies,colorEffect,grayLevel,craftType,tenant[shortName],preparedBy,mark,matchRating,rgb,comment,status,colors[id,colorLayer,goods[id,workshopGoodsSpecification[id,goods[id,serialNo,name]]],weight],pearls[id,colorLayer,goods[id,workshopGoodsSpecification[goods[serialNo,name]]],weight]`

export default factory({
  components: { ServiceFormulaTable, ServiceFormulaDetail },

  config: {
    children: {
      operation: {
        check: {
          click(row: EnospraySprayDefinitions['SprayFormulaDto']) {
            this.detail.data = row
            this.detail.visible = true
          }
        },
        option: {
          command(option: string) {
            this.detail.data = {} as EnospraySprayDefinitions['SprayFormulaDto']
            this.detail.data.procedureType = { code: option, type: '', message: '', description: '' }
            this.detail.data.status = { code: 'P', type: '', message: '', description: '' }
            this.detail.visible = true
          }
        }
      },
      origin: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { rsexp, typeCode: 'O' }
            }
          }
        }
      },
      authorized: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { rsexp, typeCode: 'C', audited: true }
            }
          }
        }
      },
      unauthorized: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { rsexp, typeCode: 'O', audited: false }
            }
          }
        }
      },
      detail: {
        visible: false,
        data: {} as EnospraySprayDefinitions['SprayFormulaDto'],
        confirm() {
          this.origin.get()
          this.authorized.get()
          this.unauthorized.get()
        }
      }
    }
  },

  mounted() {
    this.origin.get()
    this.authorized.get()
    this.unauthorized.get()
  }
})
</script>
